<template>
    <el-container>
        <el-aside width="200px" class="sidebar">
      <el-menu
        :default-active="activeMenu"
        class="el-menu-vertical"
        @select="handleMenuSelect"
        router
      >
        <!-- Your sidebar menu items -->
        <!-- <el-menu-item index="/portfolio">Transactions</el-menu-item>
        <el-menu-item index="/portfolio">Currencies</el-menu-item> -->
        <!-- <el-submenu index="portfolio">
          <template slot="title">Currency</template>
          <el-menu-item index="/portfolio/currency">Currency</el-menu-item>
          <el-menu-item index="/portfolio/transactions">Transactions</el-menu-item>
        </el-submenu> -->
      </el-menu>
    </el-aside>
    <el-container>
        <el-header style="text-align: right; font-size: 12px">
      <el-dropdown>
        <i class="el-icon-add" style="margin-right: 15px">Transactions</i>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item>View</el-dropdown-item> -->
          <el-dropdown-item>    <el-button type="primary" @click="openDialog">Create Transaction</el-button></el-dropdown-item>
          <!-- <el-dropdown-item>Delete</el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <el-button type="danger" round><span>Log Out</span></el-button> -->
    </el-header>
        <el-main>
            <CurrencyTab></CurrencyTab>
            <TransactionTab></TransactionTab>
        </el-main>

        <el-dialog
            title="Create New Transaction"
            :visible.sync="dialogVisible"
            width="40%"
          >
                <el-form :model="newTransaction" ref="transactionForm" label-width="220px">
                  <!-- Add form fields for the transaction model -->
                          <el-form-item label="Sender Country" required>
                            <el-select v-model="newTransaction.sendercountry" placeholder="Activity zone">
                              <el-option label="Zone one" value="shanghai"></el-option>
                              <el-option label="Zone two" value="beijing"></el-option>
                            </el-select>
                          </el-form-item>

                          <el-form-item label="Reciever Country" required>
                            <el-select v-model="newTransaction.receivercountry" placeholder="Activity zone">
                              <el-option label="Zone one" value="shanghai"></el-option>
                              <el-option label="Zone two" value="beijing"></el-option>
                            </el-select>
                          </el-form-item>

                          <el-form-item label="Receiver Amount">
                            <el-input v-model="newTransaction.receiveramount"></el-input>
                          </el-form-item>

                          <el-form-item label="Sender Amount">
                            <el-input v-model="newTransaction.senderamount"></el-input>
                          </el-form-item>

                          <el-form-item label="Sender">
                            <el-input v-model="newTransaction.sender"></el-input>
                          </el-form-item>

                          <el-form-item label="Receiver">
                            <el-input v-model="newTransaction.receiver"></el-input>
                          </el-form-item>

                          <el-form-item label="Sender Phone Number">
                            <el-input v-model="newTransaction.senderphone"></el-input>
                          </el-form-item>

                            <el-form-item label="ReceiverPhoneNumber">
                              <el-input v-model="newTransaction.receiverphone"></el-input>
                            </el-form-item>

                            <el-form-item label="Description">
                              <el-input v-model="newTransaction.description"></el-input>
                            </el-form-item>
                
                  <!-- </el-col> -->
                  <!-- Add more form fields for other transaction attributes -->

                          <el-button @click="closeDialog">Cancel</el-button>
                          <el-button type="primary" @click="createTransaction">Create</el-button>
                  </el-form>
          </el-dialog>
    </el-container>
    </el-container>
  </template>
  
  <script>
import CurrencyTab from './CurrencyTab.vue';
import TransactionTab from './TransactionTab.vue'
import axios from 'axios';

  export default {
    name: "Sidebar",
    data() {
        return {
            activeMenu: this.$route.path,
            dialogVisible: false,
          newTransaction: {
            sender         : "", 
            receiver        : "",
            sendercountry   : "",
            receivercountry : "",
            senderamount    : "",
            senderphone     : "",
            receiveramount  : "",
            receiverphone   : "",
            completed       : "",
            timestamp       : "",
            description     : "",
            // Add more attributes here
          },

        };
    },
    methods: {
        handleMenuSelect(index) {
            this.activeMenu = index;
        },
        openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    createTransaction() {
      // Use Axios to send a POST request to your Django backend
      axios
        .post("/api/tx/mark-transaction-as-complete/", this.newTransaction)
        .then((response) => {
          this.closeDialog();
          console.error('transactions:', response);
          // Handle success (e.g., show a success message)
        })
        .catch((error) => {
          console.error('transactions:', error);
          // Handle error (e.g., show an error message)
        });
    },
    },
    components: { CurrencyTab, TransactionTab }
};
  </script>
  
  <style scoped>
  .sidebar {
    background-color: #35495e;
    color: white;
  }
  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
  
  </style>
  