<template>

  <footer data-v-25217279="" class="bd-footer text-muted">
    <div data-v-25217279="" class="container">
      <div data-v-25217279="" class="row justify-content-center">
        <div
          data-v-25217279=""
          class="text-center text-sm-right col-sm order-sm-3 col-12"
        >
          <a
            data-v-25217279=""
            href="/"
            aria-current="page"
            class="d-inline-block mx-auto nuxt-link-exact-active nuxt-link-active"
            target="_self"
            >
            <img src="../assets/amana_logo.png" class="d-inline-block align-top logo" alt="Kitten">
          </a>
        </div>
        <div data-v-25217279="" class="text-left col-md-4 col-auto">
          <h5 data-v-25217279="" class="bd-text-purple-bright mb-1">
            Useful Links
          </h5>
          <ul data-v-25217279="" class="list-unstyled ml-3">
            <li data-v-25217279="">
              <a
                data-v-25217279=""
                href="/"
                aria-current="page"
                class="nuxt-link-exact-active nuxt-link-active"
                target="_self"
                ><router-link to="/about">Who we are</router-link></a
              >
            </li>
            <!-- <li data-v-25217279="">
              <a data-v-25217279="" href="/docs" class="" target="_self"
                > <router-link to="/doctors">Doctors</router-link></a
              >
            </li> -->
            <!-- <li data-v-25217279="">
              <a
                data-v-25217279=""
                href="/docs/components"
                class=""
                target="_self"
                >Components</a
              >
            </li> -->
            <!-- <li data-v-25217279="">
              <a
                data-v-25217279=""
                href="/docs/directives"
                class=""
                target="_self"
                > <router-link to="/about">About</router-link>Amana App</a
              >
            </li> -->
            <!-- <li data-v-25217279="">
              <a data-v-25217279="" href="/docs/icons" class="" target="_self"
                >Icons</a
              >
            </li> -->
            <!-- <li data-v-25217279="">
              <a
                data-v-25217279=""
                href="/reference"
                class=""
                target="_self"
                > <router-link to="/reference">Reference</router-link></a
              >
            </li> -->
            <!-- <li data-v-25217279="">
              <a data-v-25217279="" href="/play" class="" target="_self"
                >Playground</a
              >
            </li> -->
          </ul>
          <!-- <h5 data-v-25217279="" class="bd-text-purple-bright mb-1 mt-3">
            Themes
          </h5>
          <ul data-v-25217279="" class="list-unstyled ml-3">
            <li data-v-25217279="">
              <a data-v-25217279="" href="/themes" class="" target="_self"
                >Themes and dashboards</a
              >
            </li> -->
          <!-- </ul> -->
        </div>
        <div data-v-25217279="" class="text-left col-auto">
          <h5 data-v-25217279="" class="bd-text-purple-bright mb-1">
            Community
          </h5>
          <ul data-v-25217279="" class="list-unstyled ml-3">
            <!-- <li data-v-25217279="">
              <a
                data-v-25217279=""
                href="https://github.com/bootstrap-vue/bootstrap-vue"
                target="_blank"
                >GitHub</a
              >
            </li> -->
            <li data-v-25217279="">
              <a
                data-v-25217279=""
                href="https://discord.gg/j2Mtcny"
                target="_blank"
                >Discord chat Coming Soon</a
              >
            </li>
            <li data-v-25217279="">
              <a
                data-v-25217279=""
                href="https://twitter.com/BootstrapVue"
                target="_blank"
                >Twitter Coming Soon</a
              >
            </li>
            <!-- <li data-v-25217279="">
              <a
                data-v-25217279=""
                href="https://opencollective.com/bootstrap-vue/"
                target="_blank"
                >Open Collective</a
              >
            </li> -->
          </ul>
          <!-- <h5 data-v-25217279="" class="bd-text-purple-bright mb-1 mt-3">
            Release notes
          </h5>
          <ul data-v-25217279="" class="list-unstyled ml-3">
            <li data-v-25217279="">
              <a
                data-v-25217279=""
                href="/docs/reference/changelog"
                class=""
                target="_self"
                >Changelog</a
              >
            </li>
          </ul> -->
        </div>
      </div>
      <hr data-v-25217279="" />
       <p class="">
        Unlock the Power of Transparency in Currency Exchange! Discover the secrets of fair and open currency price on our informational website. <br />
        Empowering visitors with knowledge that shines a light on every exchange transaction.
             
         <a href="darkeagent@gmail.com" target="_blank">Let us know.</a>
          </p>
        <p class="text-center p-3 craft">
            © 2023 Copyright:
            <a class="" href="https://mdbootstrap.com/">All Rights Reserved © Seijelli.Inc</a>
        </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer",
  setup() {
    return {};
  },
};
</script>

<style scoped>
footer {
  color: #333;
  font-size: 1em;
  font-weight: 400;
  font-family: "Varela Round", serif;
  margin-top: 10px;
  margin-bottom: 50px;
  text-align: center;
  bottom: 0;
  height: auto;
}

footer .note {
  font-size: 85%;
}

.logo {
width: 50%;
}
</style>
