<!-- src/components/Login.vue -->

<template>
    <div class="login-container">
      <el-card class="login-card" shadow="hover">
        <h2>Login</h2>
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
          <input type="hidden" name="csrfmiddlewaretoken" value="{% csrf_token %}">
          <el-form-item label="phone" prop="phone">
            <el-input v-model="loginForm.phone" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="password" prop="password">
            <el-input type="password" v-model="loginForm.password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="verifyUser">Login</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </template>
  
  <script>
import axios from "axios";
import Cookies from 'js-cookie';
// axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
// axios.defaults.xsrfCookieName = 'csrftoken';
// axios.defaults.withCredentials = true;

export default {
    name: "login",
    data() {
      return {
        csrfToken: null,
        loginForm: {
          phone: '+18157645878',
          password: '123',
        },
        loginRules: {
          phone: [
            { required: true, message: 'Please enter your phone', trigger: 'blur' },
          ],
          password: [
            { required: true, message: 'Please enter your password', trigger: 'blur' },
          ],
        },
      };
    },
    mounted() {
    // Fetch the CSRF token from your Django backend
    axios.get('/accounts/get-csrf-token/')
      .then((response) => {
        this.csrfToken = response.data.csrf_token;
      })
      .catch((error) => {
        console.error('Failed to fetch CSRF token:', error);
      });
  },
    methods: {
      login() {
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            // Perform login logic here
           
            console.log('Login successful');
          } else {
            console.log('Validation error');
            return false;
          }
        });
      },
      verifyUser() {
        
        // Replace with actual phone and password input values
        // if (csrfTokenInput) {
        
        const phone = this.loginForm.phone;
        const password = this.loginForm.password;

        
              this.$refs.loginForm.validate((valid) => {
              if (valid) {
                  // Perform login logic here

                  console.log('Login successful', this.csrfToken);
                  Cookies.set('csrf_token', this.csrf_token);
                  axios.post('/accounts/verify-user/', new URLSearchParams({
                  
              phone: phone,
              password: password
              // Other data you want to send
          }),
            {
                headers: {
                  'X-CSRFToken': this.csrfToken, // Include the CSRF token in the headers
                  
                }}
        )
            .then(response => {
                // User verified successfully, handle the response
                console.log(response.data.message);
                Cookies.set('csrf_token', response.data.csrf_token);
                this.$router.push('/portfolio'); 
            })
            .catch(error => {
                // User verification failed, handle the error
                console.error(error.response.data.error);
            });
          } else {
            console.log('Validation error');
            return false;
          }
        });
        // Make an AJAX POST request to verify the user
       
    // }
     // Use csrfToken as needed
}
    },
  };
  </script>
  
  <style scoped>
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .login-card {
    width: 350px;
    padding: 20px;
  }
  </style>
  