<template>
    <div>
      <!-- <Sidebar></Sidebar> -->
        <Sidebar />
        
      <!-- <el-main>
        <el-tabs v-model="activeTab">
          <el-tab-pane label="Currency" name="currency">
            <currency-tab></currency-tab>
          </el-tab-pane>
          <el-tab-pane label="Transactions" name="transactions">
            <transaction-tab></transaction-tab>
          </el-tab-pane>
        </el-tabs>
      </el-main> -->
    </div>
  </template>
  
  <script>
  import Sidebar from './protfolioComponents/Sidebar.vue';
  // import CurrencyTab from './protfolioComponents/CurrencyTab.vue';
  // import TransactionTab from './protfolioComponents/TransactionTab.vue';
  
  export default {
    name:"portfolio",
    components: {
      Sidebar,
      // CurrencyTab,
      // TransactionTab,
    },
    data() {
      return {
        activeTab: 'currency', // Default tab
      };
    },
  };
  </script>
  