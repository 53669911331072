<template>
  <div id="app">
    <TopNavBar /> 
    <router-view />
    <footerBar />
  </div>
  
</template>

<script>
import TopNavBar from './components/NavBar.vue';
import footerBar from './components/footer.vue'

export default {
    name: "App",
    setup(){
            return{
            }
        },
    components: { TopNavBar, footerBar }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #347571;;
  background-color: #d9d6c1;
  /* text color #347571 */
}
</style>
