import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router'

Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  el:'#app',
  router,
  components: { App },
  render: h => h(App),
}).$mount('#app')
/* eslint-disable no-new */
// new Vue({
//   router,  
//   render: h => h(App)
// }).$mount('#app');
// https://github.com/melehin/django-celery-vue-template/blob/master/frontend/public/index.html
// https://www.youtube.com/watch?v=tT64yEu9iBI&t=606s