<template>    
    <el-card class="bg-gray-400 text-white currecyTabCard">
      <div>
    <!-- <el-input v-model="search" size="mini" placeholder="Type to search" /> -->
    <el-table :data="filteredTransactions" style="width: 100%">

      <el-table-column label="Date" prop="date"></el-table-column>
      <el-table-column label="Name" prop="name"></el-table-column>
      <el-table-column align="right">
        <template slot-scope="scope">
          <!-- <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">update</el-button> -->
          <el-button size="mini" @click="handleUpdate(scope.$index, scope.row)">Update</el-button>
          
          <!-- <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">Delete</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-button v-if="displayRowCount < transactions.length" @click="loadMore">Load More</el-button>
      <el-dialog title="Transaction Details" :visible.sync="dialogVisible">
              <!-- Display more transaction details here -->
              <p>Date: {{ selectedTransaction.date }}</p>
              <p>Name: {{ selectedTransaction.name }}</p>
              <p>from: {{ selectedTransaction.sender }}</p>
              <p>to: {{ selectedTransaction.receiver }}</p>
              <p>amount: {{ selectedTransaction.amount }}</p>
              <p>description: {{ selectedTransaction.description }}</p>
              <!-- Add more fields as needed -->
              <el-button type="primary" @click="markAsComplete(selectedTransaction.transactionId)">Mark as Complete</el-button>
            </el-dialog>
      </div>
        <i class="pi pi-envelope text-3xl"></i>
    </el-card>
</template>

<script>
import axios from 'axios';

export default {
name: "TransactionTab",
data() {
    return {
        tableData: [{
          date: '2016-05-03',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles'
        }, {
          date: '2016-05-02',
          name: 'John',
          address: 'No. 189, Grove St, Los Angeles'
        }, {
          date: '2016-05-04',
          name: 'Morgan',
          address: 'No. 189, Grove St, Los Angeles'
        }, {
          date: '2016-05-01',
          name: 'Jessy',
          address: 'No. 189, Grove St, Los Angeles'
        }],
        search: '',
        transactions: [],
        dialogVisible: false,
    selectedTransaction: {
            transactionId: '', 
            date: '',
            name: '',
            sender: '',
            receiver: '',
            sendercountry: '',
            receivercountry: '',
            senderamount: '',
            receiveramount: '',
            description: ''

    },
    displayRowCount: 10,
      }
    },
        computed: {
        filteredTransactions() {
          const filteredData = this.transactions.filter((data) =>
            !this.search || data.name.toLowerCase().includes(this.search.toLowerCase())
          );
          
          
          return filteredData.slice(0, this.displayRowCount);

        },
      },
    methods:{
      loadMore() {
    this.displayRowCount += 10; // Increase the display count by 10
    
  },
      handleUpdate(index) {
    this.selectedTransaction = this.transactions[index];
    if(this.transactions.length > this.displayRowCount){
            this.dialogVisible = true;
          }
    // this.dialogVisible = true;
  },
  markAsComplete(transactionId) {
    // Handle marking the transaction as complete here
    // You can make an Axios request to update the transaction
    // and then close the dialog
    console.error('transactions:', transactionId);
    axios
        .post(`/api/tx/mark-transaction-as-complete/${transactionId}/`)
        .then((response) => {
          console.error('transactions:', transactionId);
          this.dialogVisible = false;
          console.error('transactions:', response);
          // Handle success, e.g., update the local data to reflect the change
          // Reload or refresh the transactions to get the updated data
        })
        .catch((error) => {
          // Handle errors
          console.error('Error fetching transactions:', error);
        });
   
  },
    fetchTransactions() {
      axios.get('/api/tx/transaction-list/')  // Replace with your API endpoint
        .then((response) => {
          this.transactions = response.data.data;
          console.error('transactions:', this.transactions);
        })
        .catch((error) => {
          console.error('Error fetching transactions:', error);
        });
    },
    
    // handleEdit(index, row) {
    //   // Handle edit action
    // },
    // handleDelete(index, row) {
    //   // Handle delete action
    // }
    // ,
    // handleEdit(index, row) {
    //     console.log(index, row);
    //   },
    //   handleDelete(index, row) {
    //     console.log(index, row);
    //   }
    
  },
  created() {
    this.fetchTransactions();
  },
    
};
</script>

<style scoped>
.sidebar {
background-color: #35495e;
color: white;
}
.currecyTabCard{
min-width: 17rem;
width: 900px;

margin-bottom: 18px;
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 5.2em;

}



</style>
  