<template>
    <div>
      <h1>Currency Prices</h1>
    <div class="horizontal-scroll" >
      
      <el-row>
        <el-button v-for="(price, index) in CurrencyPrices" :key="price.id" class="currecyTabCard" @click="toggleDialog(index)" round> 
            <el-row>
              <p>{{price.currency}}</p>
              
            <!-- First Column -->
            <el-col :span="12">

            <div class="text-center">

                <h6>Actual</h6>
                <h2> {{price.actual_price}}</h2>
            </div>
            </el-col>

                  <!-- Second Column -->
                  <el-col :span="12">
                  <div class="text-center">
                      <h6>Trade </h6>
                      <h2>{{price.trade_price}}</h2>
                  </div>
                  </el-col>
              </el-row>
              <p>{{price.country}}</p>
              <div>
                <!-- <el-button @click="showDialog">Edit Currency Price</el-button> -->

                <el-dialog
                  title="Edit Currency Price"
                  :visible.sync="dialogVisibilities[index]"
                  width="30%"
                >
                  <el-form ref="currencyPriceForm" :model="currencyPriceObject" label-width="120px">
                    <!-- Form fields for editing -->
                    <el-form-item label="Currency">
                      <el-input v-model="currencyPriceObject.currency" :placeholder="price.currency"></el-input>
                    </el-form-item>
                    <el-form-item label="Country">
                      <el-input v-model="currencyPriceObject.country" :placeholder="price.country"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="Date">
                      <el-date-picker v-model="currencyPrice.date" type="date"></el-date-picker>
                    </el-form-item> -->
                    <el-form-item label="Trade Price">
                      <el-input v-model="currencyPriceObject.trade_price" :placeholder="price.trade_price"></el-input>
                    </el-form-item>
                    <el-form-item label="Actual Price">
                      <el-input v-model="currencyPriceObject.actual_price" :placeholder="price.actual_price"></el-input>
                    </el-form-item>
                    <el-input v-model="currencyPriceObject.index" :placeholder="index"></el-input>
                  </el-form>

                  <div slot="footer" class="dialog-footer">
                    <el-button @click.stop="closeDialog(index)">Cancel</el-button>
                    <el-button type="primary" @click="updateCurrencyPrice(index)">Save</el-button>
                  </div>
                </el-dialog>
              </div>
          </el-button>
      </el-row>
      
    <!-- <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Currency</th>
          <th>Country</th>
          <th>Date</th>
          <th>Trade Price</th>
          <th>Actual Price</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="price in CurrencyPrices" :key="price.id">
          <td>{{ price.id }}</td>
          <td>{{ price.currency }}</td>
          <td>{{ price.country }}</td>
          <td>{{ price.date }}</td>
          <td>{{ price.trade_price }}</td>
          <td>{{ price.actual_price }}</td>
        </tr>
      </tbody>
    </table> -->
    </div>
  </div>
    </template>
  <script>
import axios from "axios";
// Import the required libraries, for example, js-cookie
import Cookies from 'js-cookie';

// Retrieve the CSRF token from the cookie
const csrfToken = Cookies.get('csrf_token');
axios.defaults.headers.common['X-CSRFToken'] = csrfToken;
  export default {
    name: "CurrencyTab",
    data() {
      return {
        CurrencyPrices: [],
        dialogVisible: false,
        dialogVisibilities: [],
        currencyPriceObject: {
          currency: '', // Initialize with existing data
          currency_id: '',
          country: '',
          country_id:'',
          date: '',
          trade_price: '',
          actual_price: '',
          index:0,
          
        },
        };
    },
        mounted() {
        this.fetchCurrencyPrices();
        
    },
    created() {
      // this.dialogVisibilities = new Array(this.currencyPrices.length).fill(false);
    },
    methods: {
      toggleDialog(index) {
        if (index >= 0 && index < this.CurrencyPrices.length) {
          if(this.dialogVisibilities[index] == false){
            this.$set(this.dialogVisibilities, index, true);
            this.currencyPriceObject = { ...this.CurrencyPrices[index] };
          }
         
          console.error('currency prices:', this.currencyPriceObject);
        }
      },
      closeDialog(index) {
        this.$set(this.dialogVisibilities, index, false); 
  },
        fetchCurrencyPrices() {
        axios.get('/CurrencyPrice/api/currency-prices/')
            .then((response) => {
            this.CurrencyPrices = response.data.currency_prices;
            this.dialogVisibilities = new Array(this.CurrencyPrices.length).fill(false);
            console.error('currency prices:', response.data.currency_prices);
            
            })
            .catch((error) => {
            console.error('Error fetching currency prices:', error);
            });
        },
        showDialog() {
        this.dialogVisible = false;
        // Populate currencyPrice with data of the selected currency price to edit
      },
     
      updateCurrencyPrice(index) {
        // Send data to the backend using Axios
        // const data = { ...this.currencyPriceObject };
        console.error('Error fetching currency prices:', this.currencyPriceObject);
        const currency      = this.currencyPriceObject.currency_id; // Initialize with existing data
        const country       =  this.currencyPriceObject.country_id;
        const trade_price   = this.currencyPriceObject.trade_price;
        const actual_price   =this.currencyPriceObject.actual_price;
        // Use Axios to send updatedData to the Django backend
        // Update the currency price in the Django model
        axios.post('/CurrencyPrice/api/create-currency-price/', new URLSearchParams({
                  
          currency:     currency    ,  // Initialize with existing data
          country:      country     , 
          trade_price:  trade_price , 
          actual_price: actual_price, 
          
                  // Other data you want to send
              }), {
                headers: {
                  'X-CSRFToken': this.csrfToken, // Include the CSRF token in the headers
                  
                }})
            .then((response) => {
            // this.CurrencyPrices = response.data.currency_prices;
            this.$set(this.dialogVisibilities, index , false); 
            console.error('Successful price currency addition :', response);
            })
            .catch((error) => {
            console.error('Error fetching currency prices:', error);
            });
        // Close the dialog after updating
        this.dialogVisible = false;
      },
    },
    };
  </script>
  
  <style scoped>
  .sidebar {
    background-color: #35495e;
    color: white;
  }

  .horizontal-scroll {
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 20px;
}

/* Optionally, you can style the individual buttons within the row */
.currecyTabCard {
  margin-right: 20px; 
  min-width: 17rem;
    width: 270px;
    height: 140px;/* Adjust as needed */
}
  </style>