import Vue from 'vue'
import Router from 'vue-router'
import MainPage from '@/components/MainPage'
import Login from '@/components/login'
import About from '@/components/About'
import portfolio from '@/components/portfolio'
import Reference from "@/components/Reference"

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'MainPage',
      component: MainPage
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/portfolio',
      name: 'portfolio',
      component: portfolio
    },
    {
      path: '/about',
      name: 'About',
      component: About
    },
    {
      path: '/reference',
      name: 'Reference',
      component: Reference
    }
  ]
})
