<template>
  <div class="mainpage">
    <div id="app">
      
      <div class="container">
        <div style="flex=none; margin-top=10%;" class="css-1bd4d7q ecmlyz0" ><h1 class="css-6e4vix e1pc4cdv0">To Day's Price</h1></div>
              <el-row :gutter="5">

              <el-form :model="formData" ref="formData" >
                <el-col :span="8" >
                <div class="grid-content bg-purple">
                    <el-card class="box-card">
                      <div slot="header" class="clearfix">
                        <h1>Sender</h1>
                        <el-select v-model="selectedCurrencySender" placeholder="Select Currency" @change="handleCurrencySelection">
                        <el-option
                          v-for="currency in currencyOptionsSender"
                          :key="currency.value"
                          :label="currency.label"
                          :value="currency.value"
                        ></el-option>
                      </el-select>
                    </div>
                      <el-form-item style="height: 40px;">
                        <el-input class="text item"   type="number" v-model="formData.amountA" @input="updateAmountB" style="font-size: 16px;" >
                          
                        </el-input>
                      </el-form-item>
                        <!-- <el-button style="float: center; padding: 3px 0;  font-size: 2.2em; " type="text"> USA $</el-button> -->
                      
                      
                    </el-card>

                  </div>
                </el-col>
                <el-col :span="8">
                        <div class="grid-content bg-purple">
                        <el-card class="box-card">
                          <div slot="header" class="clearfix">
                            <h1>Country</h1>
                            <el-select v-model="selectedCountry" placeholder="Select Currency" @change="handleCountrySelection">
                                <el-option
                                  v-for="currency in CountryOptions"
                                  :key="currency.value"
                                  :label="currency.label"
                                  :value="currency.value"
                                ></el-option>
                              </el-select>
                            <!-- <el-button style="float: center; padding: 3px 0;  font-size: 2.2em; " type="text"> USA $</el-button> -->
                          </div>
                          <div class="text item">
                            <h1> =  </h1>
                          </div>
                  
                        </el-card>
                      </div>
                </el-col>
                <!-- <el-form-item label="Exchange Rate (A to B)">
                  <el-input v-model="formData.exchangeRate" @input="updateAmountB" />
                </el-form-item> -->

                <el-col :span="8"><div class="grid-content bg-purple"> <el-card class="box-card">
                      <div slot="header" class="clearfix">
                        <h1>Receiver</h1>
                        <el-select v-model="selectedCurrencyReceiver" placeholder="Select Currency" >
                        <el-option
                          v-for="currency in currencyOptionsReceiver"
                          :key="currency.value"
                          :label="currency.label"
                          :value="currency.value"
                        ></el-option>
                      </el-select>
                        <!-- <el-button style="float: center; padding: 3px 0;  font-size: 2.2em; " type="text"> SGD</el-button> -->
                      </div>
                      <!-- <div  class="text item" style="">
                        800
                      </div> -->
                      <el-form-item>
                        <el-input type="number" v-model="amountB" />
                      </el-form-item>
                    </el-card>
                    </div>
                </el-col>
                
              </el-form>
            </el-row >
        </div>
      </div>    
      </div>   
</template>

<script>
import axios from "axios";



export default {
  name: "MainPage",
  data() {
    return {
      formData: {
        amountA: 1,
        exchangeRate: 1, // Default exchange rate
      },
      selectedValueCompany: "",
      showedInsuranceType: [],
      selectedInsuranceType: "", // 界面上选择的工作类型
      selectedCompany: "", // 选择的需要作对比的公司
      companyLevelInfo: {}, // 公司等级信息
      companyInfomation: {}, // 公司网址，logo等信息
      searchCompanyText: "", // 搜索公司的搜索拦
      zipCodes: [],
      ratingCities: [],
      zipCode: "",
      pointedLocation: null,
      focusedLocation: null,
      selectedCurrencySender: 'USA',
      selectedCurrencyReceiver: 'SGD',
      selectedCountry: 'USA',
      currencyOptionsSender: {},
      countriesCurrencies: {},
      currencyOptionsReceiver: {},
      CountryOptions: {
        USA : { value: 'USA', label: 'USA' },
        CAD : { value: 'CAD', label: 'CAD' },
        // Add more currency options as needed
      },
      // stateData,
      zipCodeData: {},
      selectedIndex: null,
      stateCarriers: {},
      ratingArea: {},
      firstTwoComapniesToCompare: [],
      plansWithCompanies: {},
      CurrencyPrices: [],
    };
  },
  rules: {
    amountA: [
      { required: true, message: 'Amount is required', trigger: 'blur' },
      { type: 'number', message: 'Please enter a valid number', trigger: 'blur' },
    ],
  },
  components: {
    // CompanyButtons,
    // TableOneColumn,
    },

  methods: {
    // 获取某个职位对应的公司数组（原始的职位是一个对象，因此需要将对象中的key提炼出来变成一个数组）
    updateAmountB() {
      if (isNaN(this.formData.amountA) || this.formData.amountA < 0) {
        // If it's not a valid number or is negative, reset it to 0
        this.formData.amountA = 1;
      }else{
      this.amountB = this.formData.amountA * this.formData.exchangeRate;}
    },
   
    handleCurrencySelection(){
      this.formData.exchangeRate = this.currencyOptionsReceiver[this.selectedCurrencySender].trade_price;
        console.log('Countries Currencies:', this.formData.exchangeRate);
    },
    handleCountrySelection(){
      console.log('selected country:', this.selectedCountry);
      this.currencyOptionsSender = {value: this.selectedCountry, lable: this.selectedCountry}; 
      const isNotEmpty = Object.keys(this.currencyOptionsSender).length > 0;
      if (isNotEmpty) {
        for (const item in this.countriesCurrencies[this.selectedCountry]) {
          console.log('Countries Currencies:', item);
            // this.currencyOptionsSender[item] = {...{value: item, lable: item}, ...this.currencyOptionsSender[item]};
            this.currencyOptionsReceiver[item] = {...{value: item, lable: item}, ...this.countriesCurrencies[this.selectedCountry][item]};

        }
       
          console.log('The dictionary is not empty.');
        } else {
          console.log('The dictionary is empty.');
        }
        this.selectedCurrencySender = this.selectedCountry;
        this.formData.amountA = 1;
        this.formData.exchangeRate = this.currencyOptionsReceiver[this.selectedCurrencyReceiver].trade_price;
        console.log('Countries Currencies:', this.formData.exchangeRate);
    }
  },

  created() {
    console.log("hello");
    var _this = this;

  axios.get('/CurrencyPrice/api/currency-prices/')
      .then((response) => {
        _this.CountryOptions = {};
        _this.CurrencyPrices = response.data.currency_prices;
        _this.countriesCurrencies = {};
        _this.currencyOptionsSender = {};
        _this.currencyOptionsReceiver = {};
        response.data.currency_prices.map(item => {
           
            
            if (!(item.country in _this.countriesCurrencies)) {
              // Key doesn't exist, so add it with the value
              _this.countriesCurrencies[item.country] = {};
              _this.countriesCurrencies[item.country][item.currency] = item;
            }
            else{
              _this.countriesCurrencies[item.country][item.currency] = item;
            }
            
            if (!(item.country in _this.CountryOptions)) {
              // Key doesn't exist, so add it with the value
              _this.CountryOptions[item.country] ={...item,  ...{ value: item.country, label: item.country }};
              console.log('currency prices:', item.country);
            }
      });

      _this.currencyOptionsSender = {value: _this.selectedCountry, lable: _this.selectedCountry}; 
      const isNotEmpty = Object.keys(_this.countriesCurrencies[_this.selectedCountry]).length > 0;
      if (isNotEmpty) {
        for (const item in _this.countriesCurrencies[_this.selectedCountry]) {
          console.log('Countries Currencies:', item);
            // _this.currencyOptionsSender[item] = {...{value: item, lable: item}, ..._this.currencyOptionsSender[item]};
            _this.currencyOptionsReceiver[item] = {...{value: item, lable: item}, ...this.countriesCurrencies[this.selectedCountry][item]};
           
        }
       
          console.log('The dictionary is not empty.');
        } else {
          console.log('The dictionary is empty.');
        }
        _this.formData.exchangeRate = _this.currencyOptionsReceiver[_this.selectedCurrencyReceiver].trade_price;
        console.log('Countries Currencies:', _this.formData.exchangeRate);
      })
      .catch((error) => {
      console.error('Error fetching currency prices:', error);
      
      });
  },

  computed: {
    amountB: {

      get() {
        if (isNaN(this.formData.amountA) || this.formData.amountA < 0) {
        // If it's not a valid number or is negative, reset it to 0
        // this.formData.amountA = 1;
      }else{
        return this.formData.amountA * this.formData.exchangeRate;}
	return this.formData.amountA * this.formData.exchangeRate;
      },
      set(value) {
        if (isNaN(this.formData.amountA) || this.formData.amountA < 0) {
        // If it's not a valid number or is negative, reset it to 0
        this.formData.amountA = 1;
      }else{
        this.formData.amountA = value / this.formData.exchangeRate;}
      },
    },
    // 返回选中工作类型对应的公司中的前5个
    firstCompanies: function() {
      // console.log('this.companyLevelInfo:', this.selectedInsuranceType)
      // var jobTypeCompanies = []; //this.getKeysOfObject(this.companyLevelInfo[this.selectedInsuranceType])

      // jobTypeCompanies = Object.keys(this.zipCodeData);
      // console.log("this.companyLevelInfo:", jobTypeCompanies);
      // jobTypeCompanies = [...new Set(jobTypeCompanies)];

      // jobTypeCompanies = jobTypeCompanies.slice(2);
      // return jobTypeCompanies;

      // var keyValueArray = Object.entries(this.stateCarriers);
      // const numberOfElements = 3; // Change this value as needed
      // if()
      // const firstFewElements = this.firstTwoComapniesToCompare.reduce(
      //   (result, key) => {
      //     result[key] = this.stateCarriers[key];
      //     return result;
      //   },
      //   {}
      // );
      this.updateFirstCompanies();
      var firstFewElements = {};
      console.log("this.companyLevelInfo:", this.firstTwoComapniesToCompare);
      
      console.log("this.companyLevelInfo:", this.firstTwoComapniesToCompare);
      firstFewElements = this.firstTwoComapniesToCompare.reduce(
        (result, key) => {
          result[key] = this.stateCarriers[key];
          return result;
        },
        {}
      );
      console.log(firstFewElements);
      return firstFewElements;
    },
    firstCompaniesCompare: function() {
      // console.log("this.companyLevelInfo:", this.companyLevelInfo);
      // // var jobTypeCompanies = this.getKeysOfObject(this.companyLevelInfo[this.selectedInsuranceType])
      // var jobTypeCompanies = []; //this.getKeysOfObject(this.companyLevelInfo[this.selectedInsuranceType])

      // jobTypeCompanies = Object.keys(this.zipCodeData);
      // console.log("this.companyLevelInfo:", jobTypeCompanies);
      // jobTypeCompanies = [...new Set(jobTypeCompanies)];

      // jobTypeCompanies = jobTypeCompanies.slice(0, 2);

      // return jobTypeCompanies;

      var firstFewElements = {};
      // console.log("this.companyLevelInfo:", this.selectedValueCompany);
      // if(this.selectedValueCompany && !(this.firstTwoComapniesToCompare.includes(this.selectedValueCompany))){
      //   this.firstTwoComapniesToCompare.shift();
      //   this.firstTwoComapniesToCompare.push(this.selectedValueCompany);
      // }
      firstFewElements = this.firstTwoComapniesToCompare.reduce(
        (result, key) => {
          result[key] = this.stateCarriers[key];
          return result;
        },
        {}
      );
      console.log(firstFewElements);
      return firstFewElements;
    },
    // 返回选中工作类型对应公司中第5个后面所有的公司
    lastCompanies: function() {
      // var jobTypeCompanies = []; // this.getKeysOfObject(this.companyLevelInfo[this.selectedInsuranceType])
      // jobTypeCompanies = Object.keys(this.zipCodeData);
      // console.log("this.companyLevelInfo:", jobTypeCompanies);
      // jobTypeCompanies = jobTypeCompanies.slice(0, 2);
      // jobTypeCompanies = [...new Set(jobTypeCompanies)];

      // return jobTypeCompanies.slice(5);
      var keyValueArray = Object.keys(this.stateCarriers);

      const firstFewElements = Object.keys(this.stateCarriers)
        .slice(3, keyValueArray.length)
        .reduce((result, key) => {
          result[key] = this.stateCarriers[key];
          return result;
        }, {});

      return firstFewElements;
      // return this.stateCarriers;
    },
    // 判断是否还有更多公司
    hasMore: function() {
      // var jobTypeCompanies = this.getKeysOfObject(
      //   this.companyLevelInfo[this.selectedInsuranceType]
      // );
      // var companies = jobTypeCompanies.slice(5);
      // if (companies.length > 0) {
      //   return true;
      // }

      // return false;
      const firstFewElements = Object.keys(this.stateCarriers);

      if (firstFewElements.length > 2) {
        return true;
      }

      return false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

#top-nav {
  background-color: #ced8f6;
  padding-top: 10px;
  padding-bottom: 30px;
  /* display: inline; */
  width: 50%;
  margin-right: auto;
  margin-left: auto;
}

#map__block {
  background-color: #ced8f6;
  padding-top: 10px;
  padding-bottom: 30px;
  /* display: inline; */
}

#top-nav .logo span {
  color: #fff;
  font-size: 3.5em;
  /*  font-family: "Varela Round", sans-serif;
  font-weight: 500;
  letter-spacing: -1px; */
  /* margin-bottom: 20px; */
  /* margin-top: 0px; */
}

#top-nav .sponsor img {
  width: 200px;
}

#top-nav .jobTypeSelect {
  width: 50%;
  margin-top: 0.5em;
}

#top-nav input[type="text"] {
  font-size: 1em;
  font-weight: 700;
  font-family: "Nunito", serif;
}

#sponsor img {
  width: 1170px;
}

#main-content .popover {
  float: right;
  width: 298px;
  margin-top: 10px;
  border: 1px solid;
  overflow: hidden;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border: 1px solid rgba(0, 0, 0, 0.15);
}

#main-content .popover ul {
  overflow: hidden;
  -webkit-padding-start: 0;
  padding-left: 0;
  cursor: pointer;
}

#main-content .popover li {
  list-style-type: none;
  float: left;
  width: 35%;
  /* text-align: left; */
  padding: 2% 7%;
}

#main-content .popover .search_bar {
  margin: 10px 10px 4px;
  width: 90%;
}

#main-content .popover li:hover {
  background-color: #f4f4f4;
  text-decoration: none;
  color: #333;
}
svg {
  position: relative;
  width: 200%;
  height: 200px;
  animation: move-left 5s linear infinite;
}



@keyframes move-left {
    0% {
        left: 0;
    }
    to {
        left: -100%;
    }
}
#main-content .choice-buttons {
  padding: 20px 0 32px 0;
}

#main-content .company-button {
  float: none;
}

#main-content .salary-button {
  float: right;
  margin-left: 10px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  /* font-size: 5.2em; */
  /* font-size: large; */
}

.box-card {
  width: 100%;
  height: 40%;
  min-height: 250px;
  /* min-width: 500px; */
  /* max-width:800px; */
}
.tabs__container {
  background-color: rgba(255, 255, 255, 0.2);
  justify-content: center;
  max-width: 600px;
  width: 100%;
}

.carousel-item {
  color: #475669;
  opacity: 0.75;
  margin: 0;
  text-align: center;
  height: 100%;
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  display: flex;
  align-items: center;
  margin: 0;
  text-align: center;
  height: 100%;
}

.el-carousel__item:nth-child(2n) {
  background-color: #3b78cf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #3170b8;
}

.scrollbar-flex-content {
  display: flex;
}
.scrollbar-demo-item {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 90px;
  margin: 10px;
  text-align: center;
  border-radius: 4px;
  background: var(--el-color-danger-light-9);
  color: var(--el-color-danger);
}

.common-layout {
  /* display: flex; */
  /* align-items: center; */
  /* gap: 40px; */
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  height: 100%;

}
.time {
  font-size: 12px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  padding: 0;
  min-height: auto;
}

.image {
  width: 100%;
  display: block;
}
.carouselHight {
  /* height: 540px; */
  max-height: 650px;
  min-height: 540px;
}

.scroll-container {
  width: 200px;
}
.selected {
  background-color: #e0e0e0;
}

.custom-input {
  height: 140px; /* Adjust the height as needed */
  border: none; 
}
</style>
