<template>
  <div class="TopNavBar">
    <nav class="navbar navbar-expand-lg navbar-container navbar-light ">
      <!-- Container wrapper -->
      <div class="container-fluid">
        <!-- Toggle button -->
        <button
          class="navbar-toggler"
          type="button"
          data-mdb-toggle="collapse"
          data-mdb-target="#navbarCenteredExample"
          aria-controls="navbarCenteredExample"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="fas fa-bars"></i>
        </button>

        <!-- Collapsible wrapper -->
        <div
          class="collapse navbar-collapse justify-content-center"
          id="navbarCenteredExample"
        >
          <!-- Left links -->
          <ul class="navbar-nav mb-2 mb-lg-0">
            <b-navbar-brand>
              <a class="nav-link active" aria-current="page">
                <router-link to="/"
                  ><img
                    src="../assets/amana_logo.png"
                    class="d-inline-block align-top logo"
                    alt="Kitten"/></router-link
              ></a>
            </b-navbar-brand>
            <li class="nav-item">
              <a class="nav-link active" aria-current="page">
                <router-link to="/about">About</router-link></a
              >
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link active" aria-current="page">
                <router-link to="/doctors">Doctors</router-link></a
              >
            </li> -->
           
            <!-- <li class="nav-item">
                  <a class="nav-link" href="#">Link</a>
                </li> -->
            <!-- Navbar dropdown -->
            <!-- <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Dropdown
                  </a> -->
                  <!-- Dropdown menu -->
                  <!-- <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <a class="dropdown-item" href="#">Action</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">Another action</a>
                    </li>
                    <li><hr class="dropdown-divider" /></li>
                    <li>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </li>
                  </ul> -->
                <!-- </li> -->
                <!-- <li class="nav-item">
                      <a class="nav-link disabled"
                        >Disabled</a
                      >
                </li> -->
              </ul>
          
          <!-- Left links -->
        </div>
        <form class="d-flex">
            <!-- <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"> -->
            <button class="btn btn-outline-success" type="submit">Make A Request</button>
          </form>
        
        <!-- Collapsible wrapper -->
      </div>
      <!-- Container wrapper -->
    </nav>
  </div>
</template>

<script>
export default {
  name: "TopNavBar",
  setup() {
    return {};
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@500&display=swap");
.bgwhite {
  background-color: #d9d6c1;;
}
.hpink:hover {
  background: #ed64a6;
  color: #d9d6c1;
}
.hblue:hover {
  background: #667eea;
  color: #d9d6c1;
}
nav {
  box-shadow: 0px 0.5px 10px rgb(180, 180, 180);
}
.fa {
  color: #94a3b8;
  font-size: 2.6vh;
}
.fa:hover {
  animation-name: example;
  animation-duration: 2s;
}
.fb:hover {
  color: #ffffff;
}
.tw:hover {
  color:#ffffff;
}
.gh:hover {
  color: #ffffff;
}
@keyframes example {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.nav-item a.router-link-exact-active {
  text-shadow: 1px 8px 9px rgb(160, 156, 156);
  font-family: "Maven Pro", sans-serif;
}
.logo {
  width: 40px;
}
.navbar-container {
  /* color: #ffffff; */
  margin-left: auto;
  margin-right: auto;
  max-width: 1180px;
  width: 100%;
  /* margin-bottom:0px; */
  /* padding-top:0; */
  padding-right: 32px;
  /* padding-bottom:0; */
  padding-left: 32px;
  background-color: rgba(0, 0, 0, 0);
  /* z-index:7; */
}
</style>
